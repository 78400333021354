import { fhirAPI } from '../fhirAPI'

// Read Patient Information
export const readPatient = async (patientId) => {
	return await fhirAPI({
		url: `Patient/${patientId}`,
		method: 'GET',
		eCode: 'BP-FA-003'
	})
}

// Search Patient Information
export const searchPatient = async (contractId) => {
	return await fhirAPI({
		url: `Patient?identifier=${contractId}`,
		method: 'GET',
		eCode: 'BP-FA-004'
	})
}
