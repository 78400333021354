<template>
	<v-dialog v-model="show" width="360">
		<v-card color="white" title="Error" class="pa-2">
			<v-card-text class="text-secondary" v-if="errorMessage">
				<p v-if="eCode" class="mb-2"><strong>Error code:</strong> {{ eCode }}</p>
				<p>{{ sanitize(errorMessage) }}</p>
			</v-card-text>
			<v-card-text class="text-secondary" v-else>
				<p v-if="eCode" class="mb-2"><strong>Error code:</strong> {{ eCode }}</p>
				<p>
					An error occurred, please refresh and try again. If the issue continues,
					<router-link @click.stop="show = false" :to="contactPath">contact us.</router-link>
				</p>
			</v-card-text>

			<v-card-actions class="justify-end">
				<v-btn text="Close" @click.stop="show = false" class="link"></v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import sanitizeHtml from 'sanitize-html'
export default {
	props: {
		errorMessage: String,
		value: Boolean
	},
	computed: {
		show: {
			get() {
				return this.value || this.$showError.value
			},
			set(value) {
				this.$Error(value)
				this.$emit('update:modelValue', value)
			}
		},
		eCode() {
			return this.$errCode.value ? this.$errCode.value : false
		},
		contactPath() {
			if (this.$route.meta.requiresAuth && this.$route.name != 'SelectPlan') {
				return '/contact-us'
			} else {
				return '/contact'
			}
		}
	},
	methods: {
		sanitize(html) {
			return sanitizeHtml(html)
		}
	}
}
</script>

<style lang="scss" scoped>
:deep(.v-card-title) {
	white-space: wrap;
}
</style>
