import { createApp, ref } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'
import piniaPluginPersistedState from 'pinia-plugin-persistedstate'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { createVuetify } from 'vuetify/lib/framework.mjs'
import { ArkansasBCBSLight } from './styles/theme'
import { getContent, getContentFull } from '@/services/cms/util.js'
import ErrorModal from '@/components/ErrorModal.vue'
import LoadingSpinnerTakeover from '@/components/LoadingSpinnerTakeover.vue'

const app = createApp(App)
const vuetify = createVuetify({
	components: {
		ErrorModal,
		LoadingSpinnerTakeover
	},
	theme: {
		defaultTheme: 'ArkansasBCBSLight',
		themes: {
			ArkansasBCBSLight
		}
	},
	icons: {
		defaultSet: 'mdi',
		aliases,
		sets: {
			mdi
		}
	}
})

app.config.globalProperties.getContent = getContent
app.config.globalProperties.getContentFull = getContentFull
const showLoadingTakeover = (app.config.globalProperties.$showLoadingTakeover = ref(false))
app.config.globalProperties.$LoadingTakeover = (val) => {
	showLoadingTakeover.value = val
}
const showError = (app.config.globalProperties.$showError = ref(false))
const errCode = (app.config.globalProperties.$errCode = ref(false))
app.config.globalProperties.$Error = (val, eCode) => {
	if (val) {
		showError.value = val
		errCode.value = eCode
	} else {
		showError.value = val

		setTimeout(() => {
			errCode.value = eCode
		}, 200)
	}
}

export const globals = app.config.globalProperties

const pinia = createPinia()
pinia.use(piniaPluginPersistedState)

app.use(pinia)
app.use(vuetify)
app.use(router)

router.isReady().then(() => {
	app.mount('#app')
})
