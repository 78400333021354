import { defineStore } from 'pinia'

export const useSelectedPlan = defineStore('selectedPlan', {
	state: () => ({
		username: null,
		usersPreferredName: null,
		companyId: null,
		contractId: null,
		memberId: null,
		memberSeqNumber: null,
		isPolicyHolder: false,
		isActive: false,
		contractType: null,
		origEffDate: null,
		covTermDate: null,
		lobDisplayName: null,
		tier1: null,
		ssoType: null,
		displayName: null,
		loaded: false
	}),
	getters: {
		selectedPlan() {
			return this.$state
		},
		isFutureEffective() {
			return new Date() <= new Date(this.$state.origEffDate)
		}
	},
	actions: {
		updateSelectedPlan(payload) {
			Object.keys(payload).forEach((key) => {
				if (Object.keys(this.$state).includes(key)) {
					this.$state[key] = payload[key]
				}
			})
		}
	},
	persist: {
		storage: sessionStorage
	}
})
