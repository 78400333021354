import { getUsersMemberships } from '@/services/api/users/users.js'
import { useSelectedPlan } from '@/stores/plans/selectedPlan.js'
import { useMemberships } from '@/stores/userInfo/memberships.js'

// Retrieves store if it's populated.
// If this the first time accessing the store (store is empty),
// then it makes the API call and updates/returns the store
export async function retrieveMemberships(username) {
	const store = useMemberships()

	if (!store.loaded) {
		await getUsersMemberships(username)
			.then((res) => {
				store.setMemberships(res)
				store.loaded = true
			})
			.catch((err) => {
				console.error(err)
			})
	}

	return store
}
