import { searchCoverage } from '@/services/api/fhirAPI/coverage/coverage.js'
import { useCoverage } from '@/stores/coverage/coverage.js'
import { useSelectedPlan } from '@/stores/plans/selectedPlan.js'
import { retrieveMemberships } from '@/composables/memberships.comp.js'
import { retrieveAccessRights } from '../composables/accessRights.comp.js'

// Retrieves store if it's populated.
// If this the first time accessing the store (store is empty),
// then it makes the API call and updates/returns the store
export async function retrieveCoverage() {
	const store = useCoverage()
	const planStore = useSelectedPlan()
	const rightsStore = await retrieveAccessRights()
	const membershipsStore = await retrieveMemberships(planStore.username)

	const planType = planStore.contractType.toLowerCase()

	function twelveFromToday() {
		const today = new Date()
		const dateMax = new Date(today)
		const newDate = new Date(dateMax.setMonth(dateMax.getMonth() - 12))

		return newDate
	}

	function withinXMonths(val, months) {
		const today = new Date()
		const dateMax = new Date(today)
		dateMax.setMonth(dateMax.getMonth() - months)
		const dateToCheck = new Date(val)
		return dateMax <= dateToCheck
	}

	if (!store.loaded) {
		let apiPlanId = planStore.isPolicyHolder ? planStore.contractId : planStore.memberId
		await searchCoverage(
			apiPlanId,
			`&versionId=2&_count=50&period=ge${twelveFromToday().getFullYear()}-01-01`
		)
			.then((res) => {
				if (res.total === 0) return
				res.loaded = true
				res.entry.forEach((covItem) => {
					const covItemType = covItem.resource.type.coding[0].code.toLowerCase()
					// check if active AND not already in store
					if (
						(covItem.resource.status === 'active' ||
							(covItem.resource.status === 'cancelled' &&
								withinXMonths(covItem.resource.period.end, 12))) &&
						!(covItem.resource.id in store.coverage)
					) {
						// HIP
						if (covItemType === 'hip' && covItem.resource.id.includes(planStore.contractId)) {
							store.updateCoverage(covItem.resource.id, covItem.resource)
						}
						// Dental
						else if (
							covItemType === 'dental' &&
							covItemType === planStore.contractType.toLowerCase() &&
							covItem.resource.id.includes(planStore.contractId)
						) {
							store.updateCoverage(covItem.resource.id, covItem.resource)
						}
						// Vision
						else if (
							covItemType === 'vision' &&
							covItemType === planStore.contractType.toLowerCase() &&
							covItem.resource.id.includes(planStore.contractId)
						) {
							store.updateCoverage(covItem.resource.id, covItem.resource)
						}
					}
				})
			})
			.catch((err) => {
				console.error(err)
			})
	}

	return store
}
