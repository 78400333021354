import { fhirAPI } from '../fhirAPI'

export const readCoverage = async (memberId) => {
	return await fhirAPI({
		url: `Coverage/${memberId}`,
		method: 'GET',
		eCode: 'BP-FA-005'
	})
}

export const searchCoverage = async (memberId, params) => {
	return await fhirAPI({
		url: `Coverage?identifier=${memberId}${params}`,
		method: 'GET',
		eCode: 'BP-FA-006'
	})
}

export const findCoverage = async (contractId, params, count = 50) => {
	return await fhirAPI({
		url: `Coverage?identifier=${contractId}&versionId=2&${params}&_count=${count}`,
		method: 'GET',
		eCode: 'BP-FA-007'
	})
}
