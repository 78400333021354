import { API } from '../axios'

//Basic function that sets up all Authenticated User API calls.
export const users = async (props) => {
	const { url, method, headers, body, eCode } = props
	const path = url !== undefined ? url : ''
	return await API({
		url: `users/${path}`,
		method,
		headers,
		body,
		eCode
	})
}

//Meant for user registration steps 1-4
export const usersRegistration = async (body) => {
	return await API({
		url: 'public/users',
		method: 'POST',
		body,
		eCode: 'BP-NFA-003'
	})
}

//Meant for user forgot user name
export const usersForgotName = async (body) => {
	return await API({
		url: 'public/users/forgotUserName',
		method: 'POST',
		body
	})
}

//Meant for user forgot user password
export const usersForgotPassword = async (body) => {
	return await API({
		url: 'pubic/users/forgotUserPassword',
		method: 'POST',
		body
	})
}

//Meant for changing passwords
export const usersChangePassword = async (body, user) => {
	return await users({
		url: `${user}/password`,
		method: 'PUT',
		body,
		eCode: 'BP-NFA-021'
	})
}

//Get Access Rights for a user with name and code
export const getUsersAccess = async (user, companyId, code, contractType) => {
	return await users({
		url: `${user}/accessrights/${companyId}/${code}/${contractType}`,
		method: 'GET',
		eCode: 'BP-NFA-001'
	})
}

//Get Memberships of a particular user
export const getUsersMemberships = async (user) => {
	return await users({
		url: `${user}/memberships`,
		method: 'GET',
		eCode: 'BP-NFA-002'
	})
}

//Delete a user
export const deleteUser = async (user) => {
	return await users({
		url: `${user}`,
		method: 'DELETE',
		eCode: 'BP-NFA-022'
	})
}
