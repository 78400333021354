import { API } from '../axios'

//Get system avilablity
export const getSystemAvailability = async () => {
	return await API({
		url: `public/systemavailability`,
		method: 'GET',
		eCode: 'BP-NFA-009'
	})
}
