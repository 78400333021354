import { defineStore } from 'pinia'

export const useMemberships = defineStore('memberships', {
	state: () => ({
		contracts: null,
		loaded: false
	}),
	getters: {
		getMemberships() {
			return this.$state
		}
	},
	actions: {
		setMemberships(payload) {
			this.contracts = payload.userMemberships
		},
		isEmbedded(memberId) {
			let count = 0
			this.$state.contracts.forEach((x) => {
				if (x.memberId === memberId) count += 1
			})

			return count > 1 ? true : false
		}
	},
	persist: {
		storage: sessionStorage
	}
})
